// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o4vtoH48vLE7gf8KPSKA{display:none !important}`, "",{"version":3,"sources":["webpack://./../../../tools/nodeGeometryEditor/dist/graphSystem/blockNodeData.modules.scss"],"names":[],"mappings":"AAAA,sBACI,uBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": `o4vtoH48vLE7gf8KPSKA`
};
export default ___CSS_LOADER_EXPORT___;
