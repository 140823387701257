// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aesRSUyZNb7c_sVNBrZn{width:40px;grid-template-rows:0px 40px 0px;border-radius:5px;transform:translateY(-7px)}.gqCNORF8fTeQJNw3K8Y6{display:none}.VvGxrGVeHQgGTM_2I6OV{transform:translateY(7px)}.vk3d5pnQ11QatR3s7sK2{border-radius:0px}.X6gePC9aWPDVF43qW5MT{width:20px;transform:translate(16px, 10px);background:rgba(0,0,0,0);border:none;display:block;content:"?";grid-row:1}`, "",{"version":3,"sources":["webpack://./../../../tools/nodeGeometryEditor/dist/graphSystem/display/debugDisplayManager.modules.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,+BAAA,CACA,iBAAA,CACA,0BAAA,CAGJ,sBACI,YAAA,CAGJ,sBACI,yBAAA,CAGJ,sBACI,iBAAA,CAGJ,sBACI,UAAA,CACA,+BAAA,CACA,wBAAA,CACA,WAAA,CACA,aAAA,CACA,WAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"debugBlock": `aesRSUyZNb7c_sVNBrZn`,
	"hidden": `gqCNORF8fTeQJNw3K8Y6`,
	"translatedConnections": `VvGxrGVeHQgGTM_2I6OV`,
	"roundSelectionBorder": `vk3d5pnQ11QatR3s7sK2`,
	"debugContent": `X6gePC9aWPDVF43qW5MT`
};
export default ___CSS_LOADER_EXPORT___;
