// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stbiu24JbUBfHNSSYhSR{position:absolute;width:100%;height:100%;background:rgba(.1,.1,.1,.6);display:grid;font-family:"acumin-pro";top:0}.stbiu24JbUBfHNSSYhSR .fCGAiNfXRhhXF8hQPpct{align-self:center;justify-self:center;min-height:140px;max-width:400px;border-radius:10px;background:#fff;padding:10px;display:grid;grid-template-columns:100%;grid-template-rows:1fr 50px}.stbiu24JbUBfHNSSYhSR .fCGAiNfXRhhXF8hQPpct .yRrm4hWjzyu4RnCc412h{grid-row:1;grid-column:1;margin-top:20px;padding:10px;font-size:18px;color:#000}.stbiu24JbUBfHNSSYhSR .fCGAiNfXRhhXF8hQPpct .c5hG_qW6gImPmY2mRXUf{grid-row:2;grid-column:1;display:grid;grid-template-rows:100%;grid-template-columns:100%;color:#fff}.stbiu24JbUBfHNSSYhSR .fCGAiNfXRhhXF8hQPpct .c5hG_qW6gImPmY2mRXUf .TaDL8j02NlDZlEf6mraY{cursor:pointer;justify-self:center;background:green;min-width:80px;justify-content:center;display:grid;align-content:center;align-self:center;height:35px;border-radius:10px}.stbiu24JbUBfHNSSYhSR .fCGAiNfXRhhXF8hQPpct .c5hG_qW6gImPmY2mRXUf .TaDL8j02NlDZlEf6mraY:hover{opacity:.8}.stbiu24JbUBfHNSSYhSR .fCGAiNfXRhhXF8hQPpct .c5hG_qW6gImPmY2mRXUf .TaDL8j02NlDZlEf6mraY.gWN6zWjwiaTlrWBw3gRR{background:red}`, "",{"version":3,"sources":["webpack://./../../../dev/sharedUiComponents/dist/components/MessageDialog.modules.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,UAAA,CACA,WAAA,CACA,4BAAA,CACA,YAAA,CACA,wBAAA,CACA,KAAA,CAEA,4CACI,iBAAA,CACA,mBAAA,CACA,gBAAA,CACA,eAAA,CACA,kBAAA,CACA,eAAA,CACA,YAAA,CAEA,YAAA,CACA,0BAAA,CACA,2BAAA,CAEA,kEACI,UAAA,CACA,aAAA,CACA,eAAA,CACA,YAAA,CACA,cAAA,CACA,UAAA,CAGJ,kEACI,UAAA,CACA,aAAA,CACA,YAAA,CACA,uBAAA,CACA,0BAAA,CACA,UAAA,CAEA,wFACI,cAAA,CACA,mBAAA,CACA,gBAAA,CACA,cAAA,CACA,sBAAA,CACA,YAAA,CACA,oBAAA,CACA,iBAAA,CACA,WAAA,CACA,kBAAA,CAEA,8FACI,UAAA,CAGJ,6GACI,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog-container": `stbiu24JbUBfHNSSYhSR`,
	"dialog": `fCGAiNfXRhhXF8hQPpct`,
	"dialog-message": `yRrm4hWjzyu4RnCc412h`,
	"dialog-buttons": `c5hG_qW6gImPmY2mRXUf`,
	"dialog-button-ok": `TaDL8j02NlDZlEf6mraY`,
	"error": `gWN6zWjwiaTlrWBw3gRR`
};
export default ___CSS_LOADER_EXPORT___;
