// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bqOjoz2V3bK4o2j0PpL9{grid-row:2;min-height:34px;text-align:center;font-size:18px;font-weight:bold;margin:0 10px 5px;display:grid;align-content:center}.bqOjoz2V3bK4o2j0PpL9.hrNbNfFQ7Fj6hbQcVIqj{font-size:17px}.EmUAB9cKhfUggRFM5Wc8{border-color:#464348;background:#464348}.rQcknTkBfgvc81C4eF3W{border-color:#66491b;background:#66491b}`, "",{"version":3,"sources":["webpack://./../../../tools/nodeGeometryEditor/dist/graphSystem/display/inputDisplayManager.modules.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,eAAA,CACA,iBAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,YAAA,CACA,oBAAA,CAEA,2CACI,cAAA,CAIR,sBACI,oBAAA,CACA,kBAAA,CAGJ,sBACI,oBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-block": `bqOjoz2V3bK4o2j0PpL9`,
	"small-font": `hrNbNfFQ7Fj6hbQcVIqj`,
	"contextual": `EmUAB9cKhfUggRFM5Wc8`,
	"inspector": `rQcknTkBfgvc81C4eF3W`
};
export default ___CSS_LOADER_EXPORT___;
