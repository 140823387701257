// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T_lRFnwJInOlDFgKz4OK{width:100%;height:100%;margin:0;padding:0;font:14px "acumin-pro";user-select:none;overflow:hidden;cursor:move;position:relative;background-image:linear-gradient(to right, #4f4e4f 1px, transparent 1px),linear-gradient(to bottom, #4f4e4f 1px, transparent 1px)}.V8GNvWXaeyg9U9VJb61N{width:100%;height:100%;left:0;top:0;transform-origin:left top;display:grid;grid-template-rows:100%;grid-template-columns:100%}.FqcYTGPcQaCdFsdryWkx{overflow:visible;grid-row:1;grid-column:1;position:relative;width:100%;height:100%}.oyID5hyodlY60Yr2pjV4{grid-row:1;grid-column:1;position:relative;width:100%;height:100%;overflow:visible;pointer-events:none;z-index:2;filter:drop-shadow(7px 6px 2px rgba(0, 0, 0, 0.2))}._Fb75uBYncvwPhTl2Kvu{grid-row:1;grid-column:1;position:relative;width:100%;height:100%}.CcJV3SELovtpbEKcOvwg{pointer-events:none}.PMGIKlRc0MQBQai5FH7S{z-index:10;position:absolute;background:rgba(72,72,196,.5);border:blue solid 2px}`, "",{"version":3,"sources":["webpack://./../../../dev/sharedUiComponents/dist/nodeGraphSystem/graphCanvas.modules.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,WAAA,CACA,QAAA,CACA,SAAA,CACA,sBAAA,CACA,gBAAA,CACA,eAAA,CACA,WAAA,CACA,iBAAA,CACA,iIAAA,CAGJ,sBACI,UAAA,CACA,WAAA,CACA,MAAA,CACA,KAAA,CACA,yBAAA,CACA,YAAA,CACA,uBAAA,CACA,0BAAA,CAGJ,sBACI,gBAAA,CACA,UAAA,CACA,aAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CAGJ,sBACI,UAAA,CACA,aAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA,CACA,mBAAA,CACA,SAAA,CACA,kDAAA,CAGJ,sBACI,UAAA,CACA,aAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CAGJ,sBACI,mBAAA,CAGJ,sBACI,UAAA,CACA,iBAAA,CACA,6BAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"graph-canvas": `T_lRFnwJInOlDFgKz4OK`,
	"graph-container": `V8GNvWXaeyg9U9VJb61N`,
	"frame-container": `FqcYTGPcQaCdFsdryWkx`,
	"graph-svg-container": `oyID5hyodlY60Yr2pjV4`,
	"graph-canvas-container": `_Fb75uBYncvwPhTl2Kvu`,
	"selection-container": `CcJV3SELovtpbEKcOvwg`,
	"selection-box": `PMGIKlRc0MQBQai5FH7S`
};
export default ___CSS_LOADER_EXPORT___;
