// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.X4ogzyUWi2ko1J62HWtz{stroke-width:4px}.X4ogzyUWi2ko1J62HWtz.hDfRb0zR9Ck3gwMAp93A{stroke:#fff !important;stroke-dasharray:10,2}.cCaaNT42zjvp5Ef7O5eY{filter:brightness(1.5);stroke-width:6px}.tC04ZKUbumRiGZ4n1H3U{pointer-events:all;stroke-width:16px;opacity:0;transition:opacity 75ms;stroke:rgba(0,0,0,0);cursor:pointer}.tC04ZKUbumRiGZ4n1H3U:hover,.tC04ZKUbumRiGZ4n1H3U.hDfRb0zR9Ck3gwMAp93A{stroke:#fff !important;opacity:.4}`, "",{"version":3,"sources":["webpack://./../../../dev/sharedUiComponents/dist/nodeGraphSystem/nodeLink.modules.scss"],"names":[],"mappings":"AAAA,sBACI,gBAAA,CACA,2CACI,sBAAA,CACA,qBAAA,CAIR,sBACI,sBAAA,CACA,gBAAA,CAGJ,sBACI,kBAAA,CACA,iBAAA,CACA,SAAA,CACA,uBAAA,CACA,oBAAA,CACA,cAAA,CAEA,uEAEI,sBAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `X4ogzyUWi2ko1J62HWtz`,
	"selected": `hDfRb0zR9Ck3gwMAp93A`,
	"target-candidate": `cCaaNT42zjvp5Ef7O5eY`,
	"selection-link": `tC04ZKUbumRiGZ4n1H3U`
};
export default ___CSS_LOADER_EXPORT___;
