(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("babylonjs"), require("babylonjs-serializers"), require("babylonjs"), require("babylonjs-materials"));
	else if(typeof define === 'function' && define.amd)
		define("babylonjs-node-geometry-editor", ["babylonjs", "babylonjs-serializers", "babylonjs", "babylonjs-materials"], factory);
	else if(typeof exports === 'object')
		exports["babylonjs-node-geometry-editor"] = factory(require("babylonjs"), require("babylonjs-serializers"), require("babylonjs"), require("babylonjs-materials"));
	else
		root["NODEGEOMETRYEDITOR"] = factory(root["BABYLON"], root["BABYLON"], root["BABYLON"]["Debug"], root["BABYLON"]);
})((typeof self !== "undefined" ? self : typeof global !== "undefined" ? global : this), (__WEBPACK_EXTERNAL_MODULE__5597__, __WEBPACK_EXTERNAL_MODULE__513__, __WEBPACK_EXTERNAL_MODULE__5992__, __WEBPACK_EXTERNAL_MODULE__4208__) => {
return 